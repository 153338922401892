<template>
    <article class="card-dashboard-coin">
        <div class="card-dashboard-coin__details">
            <picture class="card-dashboard-coin__details-image">
                <img :src="info.icon" :alt="info.title" :title="info.title" />
            </picture>
            <p class="card-dashboard-coin__details-text">
                <span class="card-dashboard-coin__details-text-number">
                    {{ info.coins }}
                </span>
                {{ info.title }}
            </p>
        </div>

        <div class="card-dashboard-coin__btn">
            <NuxtLink
                no-prefetch
                v-if="isPrivate"
                :to="`/dashboard/${isCoin ? $lang.routes.coins : $lang.routes.awards}`"
                class="card-dashboard-coin__btn"
            >
                {{
                    isCoin
                        ? $lang.components.cardDashboardCoin.see_coins
                        : $lang.components.cardDashboardCoin.swap
                }}
                <div class="card-dashboard-coin__btn-icon">
                    <i class="fal fa-arrow-right fas-sm"></i>
                </div>
            </NuxtLink>
        </div>
    </article>
</template>

<script lang="ts">
export default defineComponent({
    name: 'CardDashboardCoin',
    props: {
        info: {
            type: Object,
            required: true,
        },
        isPrivate: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isCoin(): boolean {
            return this.info.title.includes(this.$lang.components.cardDashboardCoin.coins)
        },
    },
})
</script>

<style lang="postcss" scoped>
.card-dashboard-coin {
    @apply relative space-y-1 p-1;
    &__details {
        @apply flex gap-1;
        &-image {
            @apply h-5 w-5 flex-none;
            img {
                @apply h-full w-full object-contain;
            }
        }
        &-text {
            @apply text-sm font-medium text-[#404040];
            &-number {
                @apply font-semibold;
            }
        }
    }

    &__btn {
        @apply flex w-full justify-end;
        a {
            @apply flex w-fit items-center gap-1 rounded-full bg-site-primary px-3 py-1 text-xs text-white transition duration-200 lg:hover:brightness-110;
        }
    }
}
</style>
